<template>
  <div class="n-footer">
    <div class="container">
      <!-- 关于我们 -->
      <div class="f-info">
        <!-- 标题 -->
        <h2>关于我们</h2>
        <p>
          易宸传媒有限公司于2018年05月09日成立，公司经营范围包括：广播电视节目制作；从事互联网文化活动；销售通讯设备、电子产品、机械设备、汽车、汽车配件、摩托车配件、仪器仪表、计算机、软件及辅助设备等...
        </p>
      </div>
      <!-- 网站导航 -->
      <div class="f-list">
        <h2>网站导航</h2>
        <a href="javascript:;" @click="goHome()">网站首页</a>
        <a href="javascript:;" @click="goAc()">行业资讯</a>
        <!-- <a href="javascript:;" @click="goInfo()">关于我们</a> -->
        <a href="javascript:;" @click="goXy()">用户协议</a>
      </div>
      <!-- 联系我们 -->
      <div class="f-phone">
        <h2>联系我们</h2>
        <!-- <p>网址：www.qianyitan.cn</p> -->
        <!-- <p>电话：***</p> -->
        <p>邮箱：wlb@to-wincapital.com</p>
        <p>地址：北京市东城区和平里东街11号创新楼B座一层1-E1号</p>
        <p>
          备案：<a href="https://beian.miit.gov.cn/#/Integrated/index"
            >京ICP备2021040054号-1</a
          >
        </p>

         <p>
           <img src="/imgs/ga.png" alt="">京公网安备 11010102005845号
        </p>
      </div>
      <!-- 文字logo -->
      <div class="f-logo">
        <h2>易宸传媒有限公司</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 首页
    goHome() {
      this.$router.push("/");
    },
    // 资讯
    goAc() {
      this.$router.push("/ac1");
    },
    // 关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-footer {
  width: 100%;
  height: 255px;
  background-color: #292f32;
  position: relative;
  .container {
    height: 100%;
    @include flex();
    // 关于我们
    .f-info {
      width: 25%;
      h2 {
        color: #fff;
      }
      p {
        font-size: 12px;
        color: #888;
        line-height: 25px;
        margin-top: 15px;
      }
    }

    // 网站导航
    .f-list {
      h2 {
        color: #fff;
      }
      a {
        text-align: center;
        font-size: 14px;
        color: #888;
        margin: 15px 0;
        display: block;
      }
    }

    // 联系我们
    .f-phone {
      width: 34%;
      h2 {
        color: #fff;
        margin-bottom: 25px;
      }
      p {
        font-size: 14px;
        color: #888;
        margin: 15px 0;
        a {
          font-size: 14px;
          color: #888;
        }
      }
    }

    .f-logo {
      h2 {
        font-size: 21px;
        color: #fff;
      }
    }
  }
}
</style>
